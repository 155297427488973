import axios from "axios";
import { configUrl } from "config";

const getAllCoupons = async (page, pageSize) => {
    console.log(page, pageSize, 'pagewithPageSize');
    try {
        let response = await axios.get(`${configUrl.AssessmentServer}/api/Coupon?pageNumber=${page}&pageSize=${pageSize}`)

        console.log(response.data);
        let coupons = response.data["$values"].sort((x, y) => y.id - x.id)
        console.log(coupons, 'coupons')
        let couponData = coupons.map((x, i) => ({
            ...x,
            id: x.id,
        }));
        console.log(couponData, 'coupons1')
        return couponData
    } catch (e) {
        console.log("error occuredff", e);
    }
}

export default { getAllCoupons: getAllCoupons };