import { FormControlLabel, Snackbar, Switch, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { LoginContext } from "contexts/LoginContext";
import useCreateCoupon from "hooks/couponHooks/useCreateCoupon";
import React, { useState } from "react";
import getAllCoursesData from "services/CouponService/getAllCoursesData";
import getAllProvidersData from "services/ProviderServices/getAllProvidersData";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AddCoupon(props) {

    let { user } = React.useContext(LoginContext);

    const [couponData, setCouponData] = useState({
        id: 0,
        couponCodeText: "",
        providerId: 0,
        resourceTypeId: 3,// static value for Testseries resource
        resourceId: 0,
        discountPercentage: null,
        validFrom: new Date(),
        validTo: new Date(),
        isActive: true,
        createdBy: user ?? "system",
        createdDate: new Date(),
        updatedBy: user ?? "system",
        updatedDate: new Date(),
    });
    const [selectedProviderIds, setSelectedProviderIds] = useState(null);
    const [selectedResourceIds, setselectedResourceIds] = useState(null);
    const [providers, setProviders] = useState([]);
    const [courses, setCourses] = useState([]);
    const [couponCodeState, setCouponCodeState] = useState("success");
    const [discountState, setDiscountState] = useState("success");
    const [fromDateValue, setFromDateValue] = useState(null);
    const [toEndDateValue, setToEndDateValue] = useState(null);

    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [severity, setSeverity] = React.useState("success");

    const providerTypeOptions = [
        { id: 1, name: "Provider 1" },
        { id: 2, name: "Provider 2" },
        { id: 3, name: "Provider 3" },
        { id: 4, name: "Provider 4" },
    ];

    const courseTypeOptions = [
        { id: 1, name: "Course 1" },
        { id: 2, name: "Course 2" },
        { id: 3, name: "Course 3" },
        { id: 4, name: "Course 4" },
    ];
    //const createMutation = useMutation(createCoupon, { onSuccess });
    const createCoupon = useCreateCoupon();
    // const updateMutation = useMutation(updateCoupon, { onSuccess });

    // useEffect(() => {
    //     const fetchCouponData = async (code) => {
    //         try {
    //             const response = await getCouponByCode.getCouponByCode(code);
    //             const data = response?.data;
    //             console.log("coursesddd", data);
    //             if (data) {
    //                 setCouponData({
    //                     couponCodeText: data.couponCodeText,
    //                     providerId: data.providerId,
    //                     resourceId: data.resourceId,
    //                     discountPercentage: data.discountPercentage,
    //                     validFrom: data.validFrom,
    //                     validTo: data.validTo,
    //                     isActive: data.isActive,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error("Error fetching coursesdata:", error);
    //         }
    //     };
    //     if (props.isEdit && props?.code) {
    //         fetchCouponData(props?.code);
    //     }
    // }, [props?.isEdit]);


    const fetchCourseData = async (id) => {
        if (id) {
            try {
                const response = await getAllCoursesData.getAllCoursesData(id);
                console.log(response, "daay");
                const data = response?.data?.$values || [];
                const sortedData = data.map(course => ({
                    id: course.id,
                    name: course.name
                }));
                setCourses(sortedData);
                console.log("coursesddd", data, sortedData);
            } catch (error) {
                console.error("Error fetching coursesdata:", error);
            }
        }

    };

    const fetchProviderData = async () => {
        try {
            const response = await getAllProvidersData.getAllProvidersData();
            const data = response?.data?.$values || [];
            const sortedProviderData = data?.length > 0 ? data.sort((a, b) => a.name.localeCompare(b.name)) : [];
            setProviders(sortedProviderData);
            console.log("ddd", data);
        } catch (error) {
            console.error("Error fetching provider data", error);
        }
    };

    React.useEffect(() => {
        fetchProviderData();
        //fetchCourseData();
    }, []);

    React.useEffect(() => {
        fetchCourseData(selectedProviderIds);
    }, [selectedProviderIds]);

    const handleInputChange = (e) => {
        console.log("dara", e);
        const { name, value } = e.target;
        setCouponData({ ...couponData, [name]: value });
    };

    const handleToggleChange = (e) => {
        setCouponData({ ...couponData, isActive: e.target.checked });
    };

    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };
    const getCurrentDateTime = () => {
        const now = new Date();
        return now.toISOString().slice(0, 10); // Format: YYYY-MM-DDTHH:MM
    };

    const handleStartDateChange = (event) => {
        const newStartDate = event.target.value;
        console.log(newStartDate, "newStartDate");
        if (newStartDate !== "") {
            setFromDateValue(newStartDate);
            setCouponData({ ...couponData, validFrom: newStartDate })
        } else {
            setFromDateValue(null);
            setCouponData({ ...couponData, validFrom: null })
        }
    };
    const handleEndDateChange = (event) => {
        if (event.target.value !== "") {
            setToEndDateValue(event.target.value);
            setCouponData({ ...couponData, validTo: event.target.value })
        } else {
            setToEndDateValue(null);
            setCouponData({ ...couponData, validTo: null })
        }
    };

    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };
    const checkError = () => {
        let errorText = ""
        let errorsForm = {
            code: '',
            discount: "",
        };
        if (couponCodeState == "error") {
            errorsForm.code = "Please Enter a code.";
        }
        if (discountState == "error") {
            errorsForm.discount = "Please Enter valid discount.";
        }


    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (couponCodeState == "error" || discountState == "error") {
            showSnackBar('Please fill the required fields.', "error");
            return;
        }
        if (props.isEdit) {
            //updateMutation.mutate(couponData);
        } else {
            console.log("ata", couponData);
            console.log("ata2", couponData);
            let data = {};
            data.couponData = couponData;
            data.providerId = selectedProviderIds;
            data.resourceId = selectedResourceIds;
            //setCouponData({ ...couponData, resourceId: selectedResourceIds })
            createCoupon.mutate(data);
        }
    };

    React.useEffect(() => {
        if (createCoupon?.isError) {
            showSnackBar('Coupon creation failed', "error");
            setTimeout(() => {
                props.afterFinish();
                //props?.refetch();
            }, 2000);
        }
    }, [createCoupon?.isError])

    React.useEffect(() => {
        if (createCoupon?.isSuccess) {
            showSnackBar('Coupon created Successfully', "success");
            setTimeout(() => {
                props.afterFinish();
                props?.refetch();
            }, 2000);
        }
    }, [createCoupon?.isSuccess]);


    return (
        <Card>
            <CardHeader color="rose" icon>
                <CardIcon color="rose">
                    <i className="material-icons">{props.isEdit ? "edit" : "add"}</i>
                </CardIcon>
                <h4>{props.isEdit ? "Edit Coupon" : "Add Coupon"}</h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                            success={couponCodeState === "success"}
                            error={couponCodeState === "error"}
                            labelText={<span>Coupon Code</span>}
                            id="couponCodeText"
                            formControlProps={{ fullWidth: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={true}
                            disabled={props.isEdit}// Coupon code should not be editable in edit mode
                            inputProps={{
                                name: "couponCodeText",
                                value: couponData.couponCodeText,
                                onChange: (event) => {
                                    if (event.target.value?.length <= 25) {
                                        if (!verifyLength(event.target.value, 3)) {
                                            setCouponCodeState("error");
                                        } else {
                                            setCouponCodeState("success");
                                        }
                                        handleInputChange(event);
                                    }
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        {/* <CustomSelect
                            name="providerId"
                            label="Provider"
                            options={providerOptions}
                            setvalue={(value) => setCouponData({ ...couponData, providerId: value })}
                            value={couponData.providerId}
                        /> */}
                        <CustomSelect
                            label="Provider"
                            options={providers}
                            setvalue={(v) => {
                                console.log('slected field', v)
                                setSelectedProviderIds(v)
                            }}
                            id="providerId"
                            name="providerId"
                            ///disabled={qusetionBulkUpload.isLoading == true}
                            value={selectedProviderIds}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        {/* <CustomSelect
                            name="resourceId"
                            label="Course"
                            options={courseOptions}
                            setvalue={(value) => setCouponData({ ...couponData, resourceId: value })}
                            value={couponData.resourceId}
                        /> */}
                        <CustomSelect
                            label="Course"
                            //selectLabel="Assp"
                            options={courses}
                            setvalue={(v) => {
                                console.log('slected field', v)
                                setselectedResourceIds(v)
                            }}
                            id="resourceId"
                            name="resourceId"
                            disabled={selectedProviderIds == null}
                            value={selectedResourceIds}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <CustomInput
                            success={discountState === "success"}
                            error={discountState === "error"}
                            labelText={<span>Discount (%)</span>}
                            id="discountPercentage"
                            formControlProps={{ fullWidth: true }}
                            required={true}
                            helperText={discountState ? "Please enter correct discount(1-100)." : ""}
                            inputProps={{
                                name: "discountPercentage",
                                value: couponData.discountPercentage,
                                onChange: (event) => {
                                    if (event.target.value > 0 && event.target.value <= 100) {
                                        handleInputChange(event);
                                        setDiscountState('success');
                                    } else if (event.target.value <= 0 || event.target.value > 100) {
                                        setDiscountState('error');
                                        handleInputChange(event);
                                    } else {
                                        handleInputChange(event);
                                        setDiscountState('success');
                                    }
                                },
                                type: "number",
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <TextField
                            //style={{ marginTop: '15px' }}
                            type="date"
                            name="validFrom"
                            value={fromDateValue}
                            onChange={handleStartDateChange}
                            label="Valid From"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: getCurrentDateTime(),
                                // max: toEndDateValue, // Disable dates greater than maxEndDate
                            }}
                            style={{ marginLeft: 25, marginTop: 18 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <TextField
                            type="date"
                            name="validTo"
                            value={toEndDateValue}
                            onChange={handleEndDateChange}
                            label="Valid To"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: getCurrentDateTime(),
                            }}
                            style={{ marginTop: 18 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <FormControlLabel
                            style={{ marginTop: '8%' }}
                            control={
                                <Switch
                                    name="isActive"
                                    value={couponData.isActive}
                                    onChange={handleToggleChange}
                                    checked={couponData.isActive}
                                />
                            }
                            label={<small>Active</small>}
                        />
                    </GridItem>
                </GridContainer>
                <Button color="rose" onClick={handleSubmit} disabled={createCoupon?.isLoading} style={{ float: 'right', marginTop: 15 }}>
                    {props.isEdit ? "Update" : "Create"}
                </Button>
            </CardBody>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Card>
    );
}

export default AddCoupon;
