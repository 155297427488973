import cx from "classnames";
import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import counselorRoutes from "userBasedRoutes/counselorRoutes.js";
import providerRoutes from "userBasedRoutes/providerRoutes.js";
import studentRoutes from "userBasedRoutes/studentRoutes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { LoginContext } from "contexts/LoginContext";
import { withRouter } from "react-router";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(
    require("assets/img/sidebar-2.jpg").default
  );
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/img/logo-white.svg").default
  );

  const {
    user,
    userType,
    userPrivileges,
    providerId,
    providerType,
  } = useContext(LoginContext);

  console.log("userType", userType);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  React.useEffect(() => {
    console.log("user", user);

    if (!user || user == "") {
      props.history.push("/auth");
    }
  }, [user]);

  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg").default);
        break;
      default:
        setLogo(require("assets/img/logo-white.svg").default);
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Test.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const userBasedRoutes = () => {
    if (userType) {
      if (userType === 1 || userType === 2) {
        if (userPrivileges?.Privileges?.CategoryTopic?.length > 0) {
          // if (userPrivileges?.Privileges?.Question?.length > 0) {   // Already added previleges for Questions on line #167 
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Questions") {
              x.views.map((y, j) => {
                if (y.name == "Import Questions") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
          // }
        }
        if (userPrivileges?.Privileges?.Question?.length > 0) { // above comment is added for this 
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Questions") {
              x.views.map((y, j) => {
                if (y.name == "Questions") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }
        if (userPrivileges?.Privileges?.Test?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Tests") {
              var isTesthomePresent = false;
              var isTestPresent = false;
              var isTopicWiseTestPresent = false;
              x.views.map((y, j) => {
                if (y.path == "/testhome") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
                if (y.path == "/test") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
                if (isPrivilege("CreateTopicwiseTest") == true) {
                  if (y.path == "/tests") {
                    y.isVisible = true;
                    x.isVisible = true;
                  }
                }
              });
            }
          });
        }
        if (userPrivileges?.Privileges?.TestSeries?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Tests") {
              var isTestSeriesPresent = false;
              var isCoursePresent = false;
              x.views.map((y, j) => {
                if (y.path == "/testSeries") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
                if ((userPrivileges?.Roles?.length > 0 && userPrivileges?.Roles[0]?.Id != 8)) {
                  if (y.path == "/pyqTests") {
                    y.isVisible = true;
                    x.isVisible = true;
                  }
                }
                if (y.path == "/course") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }
        if (userPrivileges?.Privileges?.SimplifiedTest?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Tests") {
              x.views.map((y, j) => {
                if (y.path == "/simplifiedtesthome") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
                if (y.path == "/quickTest") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }
        if (userPrivileges?.Privileges?.Student?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Users") {
              x.views.map((y, j) => {
                if (y.name == "Students") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }
        if (userPrivileges?.Privileges?.User?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Manage Users") {
              x.views.map((y, j) => {
                if (y.name == "Application Users") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }
        if (userPrivileges?.Privileges?.SystemConfiguration?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Settings") {
              x.views.map((y, j) => {
                if (y.name == "System Configurations") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }

        if (userPrivileges?.Privileges?.Message?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Settings") {
              x.views.map((y, j) => {
                if (y.name == "Messages") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }

        if (userPrivileges?.Privileges?.TaskManager?.length > 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Settings") {
              x.views.map((y, j) => {
                if (y.name == "Task Manager") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }

        if (userPrivileges?.Privileges?.Provider?.length > 0 && providerId == 0) {
          providerRoutes.map((x, i) => {
            if (x.name == "Settings") {
              x.views.map((y, j) => {
                if (y.name == "Coupons") {
                  y.isVisible = true;
                  x.isVisible = true;
                }
              });
            }
          });
        }
        if (
          providerType == 5 ||
          (userPrivileges?.Privileges?.Provider?.length > 0 && providerId == 0)
        ) {
          providerRoutes.map((x, i) => {
            if (x.name == "Provider") {
              x.isVisible = true;
            }
            if (providerType == 5 && x.name == "Provision Licenses") {
              x.isVisible = true;
            }
          });
        }
        console.log(providerRoutes, "Test");
        return providerRoutes;
      }

      if (userType === 4) {
        return studentRoutes;
      }

      if (userType === 6) {
        return counselorRoutes;
      }
    }
    return userType;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={userBasedRoutes()}
        logoText={"learningFitBit"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(userBasedRoutes())}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(userBasedRoutes())}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(userBasedRoutes())}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        {/*  <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
    </div>
  );
}

export default withRouter(Dashboard);
