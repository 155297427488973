
import axios from "axios";
import { configUrl } from "config";

const getAllProvidersData = async () => {
    return axios
        .get(`${configUrl.AssessmentServer}/api/Coupon/getAllProviders`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status,
            };
        });
};
export default {
    getAllProvidersData: getAllProvidersData,
};
