import axios from "axios";
import { configUrl } from "config";
import { useMutation } from "react-query";

const useUpdateCouponStatus = () => {
    return useMutation(
        async ({ couponId, status }) => {
            const response = await axios.post(`${configUrl.AssessmentServer}/api/Coupon/activateCoupon/${couponId}/${status}`);
            return response.data;
        },
        {
            onError: (error) => {
                console.error("Error updating  status:", error);
            }
        }
    );
};

export default useUpdateCouponStatus;
