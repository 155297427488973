import CategoryTreeView from "views/Components/CategoryTreeView";
import QuestionBulkUpdate from "views/Components/QuestionBulkUpdate/QuestionBulkUpdate";
import TaskManager from "views/Components/TaskManager";
import Dashboard from "views/Dashboard/Dashboard.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HelpIcon from "@material-ui/icons/Help";
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ApplicationUserListing from "views/Components/ApplicationUserListing";
import CourseHomeComponent from "views/Components/CourseHomeComponent";
import TestHomeComponent from "views/Components/TestHomeComponent";
import Users from "views/Components/UserListing";
import CourseWizard from "views/Forms/CourseWizard";
import TestWizard from "views/Forms/TestWIzard";


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Manage Questions",
    rtlName: "rtl",
    icon: HelpIcon,
    state: "componentsCollapse",
    views: [
      {
        path: "/import",
        name: "Import Questions",
        rtlName: "rtl",
        mini: "IQ",
        rtlMini: "r",
        component: CategoryTreeView,
        layout: "/admin",
      },
      {
        path: "/questions",
        name: "Questions",
        rtlName: "نظام الشبكة",
        mini: "Q",
        rtlMini: "زو",
        component: QuestionBulkUpdate,
        layout: "/admin",
      },
      /*  {
        path: "/treeview",
        name: "TreeView",
        rtlName: "نظام الشبكة",
        mini: "T",
        rtlMini: "زو",
        component: CustomTreeView,
        layout: "/admin",
      },   */
    ],
  },
  {
    collapse: true,
    name: "Manage Tests",
    rtlName: "rtl",
    icon: "content_paste",
    state: "testCollapse",
    views: [
      {
        path: "/testhome",
        name: "Tests",
        rtlName: "rtl",
        mini: "T",
        rtlMini: "t",
        component: TestHomeComponent,
        layout: "/admin",
      },
      {
        path: "/testSeries",
        name: "Test Series",
        rtlName: "rtl",
        mini: "TS",
        rtlMini: "ts",
        component: CourseHomeComponent,
        layout: "/admin",
      },
      {
        path: "/course",
        name: "Create Course",
        rtlName: "rtl",
        mini: "CC",
        rtlMini: "cc",
        component: CourseWizard,
        layout: "/admin",
        redirect: true
      },
      {
        path: "/test",
        name: "Test",
        rtlName: "rtl",
        mini: "T",
        rtlMini: "t",
        component: TestWizard,
        layout: "/admin",
        redirect: true

      }
    ],
  },
  {
    collapse: true,
    name: "Manage Users",
    rtlName: "rtl",
    icon: Apps,
    state: "adminCrudCollapse",
    views: [
      // {
      //   path: "/importUsers",
      //   name: "Import Users",
      //   rtlName: "rtl",
      //   mini: "IU",
      //   rtlMini: "iu",
      //   component: ImportUsers,
      //   layout: "/admin",
      // },
      {
        path: "/users",
        name: "Students",
        rtlName: "rtl",
        mini: "S",
        rtlMini: "s",
        component: Users,
        layout: "/admin",
      },
      {
        path: "/applicationUsers",
        name: "Application Users",
        rtlName: "rtl",
        mini: "AU",
        rtlMini: "au",
        component: ApplicationUserListing,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Settings",
    rtlName: "rtl",
    icon: LocalLibraryIcon,
    state: "settingsCollapse",
    views: [
      {
        path: "/import",
        name: "Manage Categories",
        rtlName: "rtl",
        mini: "MC",
        rtlMini: "mc",
        component: CategoryTreeView,
        layout: "/admin",
      },
      {
        path: "/taskManager",
        name: "Task Manager",
        rtlName: "rtl",
        mini: "TM",
        rtlMini: "tm",
        component: TaskManager,
        layout: "/admin",
      },
    ],
  },

];
export default dashRoutes;
