import axios from "axios";
import { configUrl } from "config.js";

const getTargetAudience = async (isActive, isContentProvider) => {
  console.log("in audience call")
  if (isActive == undefined || isActive == "") {
    isActive = false;
  }
  if (isContentProvider == undefined) {
    isContentProvider = false;
  }
  try {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/TargetAudience/${isContentProvider}/${isActive}/${true}`
    );
    let data = response.data;

    return data;
  } catch (error) {
    console.log("error fetching targetodience ", error);
  }
};
export default {
  getTargetAudience: getTargetAudience,
}