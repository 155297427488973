import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useCreateCoupon() {
    const { config } = useContext(LoginContext);

    const queryClient = useQueryClient();
    return useMutation(
        async (couponPayload) => {
            console.log("couponPayload", couponPayload);
            let couponPayloadData = couponPayload?.couponData;
            couponPayloadData.providerId = couponPayload.providerId;
            couponPayloadData.resourceId = couponPayload.resourceId;
            let res = await axios.post(`${config.api}/api/Coupon`, couponPayloadData);
            console.log("resppone", res.data);
            return res.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("Coupon");
            },
        }
    );
}
