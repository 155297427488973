import { Backdrop } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import 'katex/dist/katex.min.css';
import React from "react";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: "#fff",
    },
}));

export default function Paragraph({ questionId, messageText, messageImage }) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isBackdropOpen, setBackdropOpen] = React.useState(false);
    const handlePopoverOpen = (event) => {
        console.log(event.currentTarget, "herrrr");
        setAnchorEl(anchorEl == null ? event.currentTarget : null);
        setBackdropOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setBackdropOpen(false);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <ImageIcon
                style={{ color: "orange", fontSize: 30 }}
                onClick={handlePopoverOpen}
            //onMouseLeave={handlePopoverClose}
            />
            {/* Backdrop */}
            <Backdrop
                className={classes.backdrop}
                open={isBackdropOpen}
                onClick={handlePopoverClose}
            />
            <Popover
                id={`mouse-over-popover-${questionId}`}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {
                    (messageImage == null && messageText == null) ?
                        "Content not available" :
                        <>
                            {(messageText != null && messageText !== "") &&
                                <Typography >{messageText}</Typography>
                            }
                            {(messageImage != null && messageImage !== "") &&
                                <img src={messageImage} alt="content" style={{ width: '100%' }} />
                            }
                        </>
                }
            </Popover>
        </>
    );
}
