
import axios from "axios";
import { configUrl } from "config";

const getAllCoursesData = async (id) => {
    return axios
        .get(`${configUrl.AssessmentServer}/api/Coupon/getAllCourses/${id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status,
            };
        });
};
export default {
    getAllCoursesData: getAllCoursesData,
};
