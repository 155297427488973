import CategoryTreeView from "views/Components/CategoryTreeView";
import QuestionBulkUpdate from "views/Components/QuestionBulkUpdate/QuestionBulkUpdate";
import systemConfigurationListing from "views/Components/SystemConfigurationListing";
import TaskManager from "views/Components/TaskManager";
import Dashboard from "views/Dashboard/Dashboard.js";
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HelpIcon from "@material-ui/icons/Help";
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MessageIcon from '@material-ui/icons/Message';
import SettingsIcon from '@material-ui/icons/Settings';
import AggregatorTransactionsListing from "views/Components/AggregatorTransactionsListing";
import ApplicationUserListing from "views/Components/ApplicationUserListing";
import CouponListing from "views/Components/CouponListing";
import CourseHomeComponent from "views/Components/CourseHomeComponent";
import CreatePyqTests from "views/Components/CreatePyqTests";
import NotificationsListing from "views/Components/NotificationListing";
import ProviderListing from "views/Components/ProviderListing";
import QuickTestForm from "views/Components/QuickTestForm";
import QuickTestHomeComponent from "views/Components/QuickTestHomeComponent";
import TestHomeComponent from "views/Components/TestHomeComponent";
import Users from "views/Components/UserListing";
import CourseWizard from "views/Forms/CourseWizard";
import TestWizard from "views/Forms/TestWIzard";
import TopicwiseTestWizard from "views/Forms/TopicwiseTestWizard";


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    isVisible: true
  },
  {
    collapse: true,
    name: "Manage Questions",
    rtlName: "rtl",
    icon: HelpIcon,
    state: "componentsCollapse",
    isVisible: false,
    views: [
      {
        path: "/import",
        name: "Import Questions",
        rtlName: "rtl",
        mini: "IQ",
        rtlMini: "r",
        component: CategoryTreeView,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/questions",
        name: "Questions",
        rtlName: "نظام الشبكة",
        mini: "Q",
        rtlMini: "زو",
        component: QuestionBulkUpdate,
        layout: "/admin",
        isVisible: false
      },
      /*  {
        path: "/treeview",
        name: "TreeView",
        rtlName: "نظام الشبكة",
        mini: "T",
        rtlMini: "زو",
        component: CustomTreeView,
        layout: "/admin",
      },   */
    ],
  },
  {
    collapse: true,
    name: "Manage Tests",
    rtlName: "rtl",
    icon: "content_paste",
    state: "testCollapse",
    isVisible: false,
    views: [
      {
        path: "/testhome",
        name: "Tests",
        rtlName: "rtl",
        mini: "T",
        rtlMini: "t",
        component: TestHomeComponent,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/testSeries",
        name: "Test Series",
        rtlName: "rtl",
        mini: "TS",
        rtlMini: "ts",
        component: CourseHomeComponent,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/tests",
        name: "Topicwise Test",
        rtlName: "rtl",
        mini: "TT",
        rtlMini: "tt",
        component: TopicwiseTestWizard,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/pyqTests",
        name: "Bulk Tests",
        rtlName: "rtl",
        mini: "TT",
        rtlMini: "tt",
        component: CreatePyqTests,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/simplifiedtesthome",
        name: "Quick Test",
        rtlName: "rtl",
        mini: "QT",
        rtlMini: "qt",
        component: QuickTestHomeComponent,
        layout: "/admin",
        isVisible: false
      },

      {
        path: "/course",
        // name: "Create Test Series",
        name: "Test Series",
        rtlName: "rtl",
        mini: "CC",
        rtlMini: "cc",
        component: CourseWizard,
        layout: "/admin",
        redirect: true,
        isVisible: false
      },
      {
        path: "/test",
        name: "Test",
        rtlName: "rtl",
        mini: "T",
        rtlMini: "t",
        component: TestWizard,
        layout: "/admin",
        redirect: true,
        isVisible: false
      },
      {
        path: "/quickTest",
        name: "Quick Test",
        rtlName: "rtl",
        mini: "T",
        rtlMini: "t",
        component: QuickTestForm,
        layout: "/admin",
        redirect: true,
        isVisible: false
      }
    ],
  },
  {
    collapse: true,
    name: "Manage Users",
    rtlName: "rtl",
    icon: Apps,
    state: "adminCrudCollapse",
    isVisible: false,
    views: [
      // {
      //   path: "/importUsers",
      //   name: "Import Users",
      //   rtlName: "rtl",
      //   mini: "IU",
      //   rtlMini: "iu",
      //   component: ImportUsers,
      //   layout: "/admin",
      // },
      {
        path: "/users",
        name: "Students",
        rtlName: "rtl",
        mini: "S",
        rtlMini: "s",
        component: Users,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/applicationUsers",
        name: "Application Users",
        rtlName: "rtl",
        mini: "AU",
        rtlMini: "au",
        component: ApplicationUserListing,
        layout: "/admin",
        isVisible: false
      },
    ],
  },

  {
    collapse: true,
    name: "Settings",
    rtlName: "rtl",
    icon: SettingsIcon,
    state: "settingsCollapse",
    isVisible: false,
    views: [
      {
        path: "/notifications",
        name: "Messages",
        rtlName: "rtl",
        icon: MessageIcon,
        rtlMini: "iu",
        component: NotificationsListing,
        layout: "/admin",
        isVisible: false,
      },
      // {
      //   path: "/import",
      //   name: "Manage Categories",
      //   rtlName: "rtl",
      //   mini: "MC",
      //   rtlMini: "mc",
      //   component: CategoryTreeView,
      //   layout: "/admin",
      // },
      {
        path: "/Configuration",
        name: "System Configurations",
        rtlName: "",
        icon: Apps,
        component: systemConfigurationListing,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/taskManager",
        name: "Task Manager",
        rtlName: "rtl",
        mini: "TM",
        rtlMini: "tm",
        component: TaskManager,
        layout: "/admin",
        isVisible: false
      },
      {
        path: "/Coupons",
        name: "Coupons",
        rtlName: "rtl",
        mini: "C",
        rtlMini: "C",
        component: CouponListing,
        layout: "/admin",
      }

    ],
  },
  {
    path: "/transactions",
    name: "Provision Licenses",
    rtlName: "rtl",
    // icon: LocalLibraryIcon,
    mini: "PL",
    rtlMini: "pl",
    component: AggregatorTransactionsListing,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/providerlist",
    name: "Provider",
    rtlName: "rtl",
    icon: LocalLibraryIcon,
    // mini: "TM",
    rtlMini: "tm",
    component: ProviderListing,
    layout: "/admin",
    isVisible: false
  }


];
export default dashRoutes;
