import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-mathtype";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button";
import { useState } from 'react';
// import ReactHtmlParser from "react-html-parser";
export default function MathEditor({ getExpression, model, contentType, onFinish }) {
    const [ckData, setCkData] = useState(model);
    const [showAlert, setShowAlert] = useState(null)
    const [flag, setFlag] = useState(false)
    const API_URL = "https://api.kraken.io/v1/url";
    const UPLOAD_ENDPOINT = "uploadImg";
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then(async (file) => {
                        const maxSizeInBytes = 30 * 1024; // 30KB
                        const fileSizeInBytes = file.size;
                        if (fileSizeInBytes > maxSizeInBytes) {
                            console.log('Image size exceeds 30KB')
                            setShowAlert(
                                <CustomAlert
                                    open={true}
                                    openFlag={() => setFlag(flag ? false : true)}
                                    severity="error"
                                    alertMessage="Image size exceeds 30KB"
                                />
                            )
                            return null;
                        }

                        console.log(file, 'filename');
                        let base64 = await convertBase64(file);
                        console.log(base64, 'fileconverted')
                        let payload = {}
                        body.append("uploadImg", base64);
                        // body.append("ckData", ckData);
                        // payload.body = body;
                        // payload.ckData = ckData;

                        console.log(body, 'body')
                        console.log(ckData, 'ckData')
                        fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
                            method: "post",
                            body: body
                        }).then((res => res.json()))
                            .then((res) => {
                                resolve({ default: `${base64}` })
                            })
                            .catch((err) => {
                                reject(err);
                            })

                    })
                })
            }
        }
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }
    function uploadPlugin(editor) {
        console.log(editor, 'loaders')
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }

    return (
        <div style={{ minHeight: '25rem', overflowY: 'auto', overflowX: 'hidden' }}>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    extraPlugins: [uploadPlugin],

                    toolbar: {
                        shouldNotGroupWhenFull: true,
                        items: [
                            // 'heading', '|',
                            // 'fontfamily', 'fontsize', '|',
                            // 'alignment', '|',
                            // 'fontColor', 'fontBackgroundColor', '|',
                            // 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                            // 'link', '|',
                            // 'outdent', 'indent', '|',
                            // 'bulletedList', 'numberedList', 'todoList', '|',
                            // 'code', 'codeBlock', '|',
                            // 'insertTable', '|',
                            // 'uploadImage', 'blockQuote', '|',
                            "heading",
                            "fontsize",
                            "alignment",
                            "fontColor",
                            "fontBackgroundColor",
                            "outdent",
                            "indent",
                            "|",
                            "bold",
                            "italic",
                            // "link",
                            "bulletedList",
                            "numberedList",
                            "imageUpload",
                            // "mediaEmbed",
                            "insertTable",
                            // "blockQuote",
                            "undo",
                            "redo",
                            "|",
                            contentType == 3 ?
                                "MathType" : '',
                            contentType == 3 ?
                                "ChemType" : '',
                        ]
                    }
                }}
                data={ckData}
                onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log(event, editor, data, 'event,editor,data');
                    setCkData(data);
                    getExpression(data);
                }}
            />
            {/* <h4 style={{ color: 'red', fontWeight: '400', marginLeft: '15px' }}>Image size exceeds 30KB</h4> */}
            {/* <div style={{ height: '160px', overflowY: 'auto', overflowX: 'hidden' }}>
        <div style={{ marginTop: '10px', color: 'black' }}><b>Output: </b></div>
        <div style={{ marginTop: '10px' }}> {ReactHtmlParser(ckData)}</div>
        <div style={{ marginTop: '10px', color: 'black' }}><b>Mathml: </b></div>
        <div style={{ marginTop: '10px', color: 'black' }}>{ckData}</div>
      </div> */}
            <Button color="primary" style={{ textTransform: 'none', position: 'absolute', bottom: '0', right: '0', marginRight: '10px' }}
                onClick={onFinish}
            >
                {"Save"}
            </Button>
            {showAlert}
        </div>
    )
}
