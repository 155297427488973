import { Switch, Tooltip, makeStyles } from "@material-ui/core";
import { ListAlt } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer.js";
import { LoginContext } from "contexts/LoginContext";
import useUpdateCouponStatus from "hooks/couponHooks/useUpdateCouponStatus";
import CustomModal from "NewComponents/CustomModal/CustomModal";
import getAllCoupons from "services/CouponService/getAllCoupons";
import AddCoupon from "views/Forms/AddCoupon";
const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: "1em",
    },
}));
export default function CouponListing(props) {
    const classes = useStyles();
    const [pageState, setPageState] = React.useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    })
    const [columns, setColumns] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { userPrivileges } = useContext(LoginContext);
    const [addCouponForm, setAddCouponForm] = useState(false);
    const [couponStatus, setCouponStatus] = React.useState();
    const [refetch, setRefetch] = React.useState();

    const { mutate: updateSubscriptionStatus, isLoading: updatingStatus, isSuccess: updateIsSuccess, error: updateError } = useUpdateCouponStatus();

    const fetchCouponsData = async (pageNumber, pageSize) => {
        setIsLoading(true);
        setPageState(old => ({ ...old, isLoading: true }));
        let getAllCouponsData = await getAllCoupons.getAllCoupons(pageNumber, pageSize);
        console.log(getAllCouponsData, 'getAllCoupons')
        setPageState(old => ({ ...old, isLoading: false, data: getAllCouponsData, total: getAllCouponsData?.length > 0 ? getAllCouponsData[0].totalCount : 0 }))
        setIsLoading(false);
    }
    useEffect(() => {
        fetchCouponsData(pageState.page, pageState.pageSize)
    }, [pageState.page, updateIsSuccess, refetch])
    const isPrivilege = (x) => {
        if (userPrivileges?.Privileges?.TaskManager.indexOf(x) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    const editCouponFormModal = async (data) => {
        setAddCouponForm(
            <CustomModal
                modal={true}
                setModal={() => hideAddCouponFormModal()}
                content={
                    <AddCoupon
                        isEdit={true}
                        code={data?.couponCodeText}
                        afterFinish={() => hideAddCouponFormModal()}
                    //refetch={userRefetch}
                    />
                }
                cancelButtonMessage="cancel"
                okButtonMessage="Edit"
                showOk={false}
                onCancelClick={() => hideAddCouponFormModal()}
                title={"Coupon Form"}
                maxWidth="lg"
                fullWidth={true}
            />
        );
    }

    const addCouponFormModal = async () => {
        setAddCouponForm(
            <CustomModal
                modal={true}
                setModal={() => hideAddCouponFormModal()}
                content={
                    <AddCoupon
                        isEdit={false}
                        //user={UserDetails?.user ?? row}
                        afterFinish={() => hideAddCouponFormModal()}
                        refetch={handleRefetch}
                    />
                }
                cancelButtonMessage="cancel"
                okButtonMessage="Create"
                showOk={false}
                onCancelClick={() => hideAddCouponFormModal()}
                title={"Coupon Form"}
                maxWidth="lg"
                fullWidth={true}
            />
        );
    };
    const hideAddCouponFormModal = () => {
        setAddCouponForm(null);
    };

    const handleRefetch = () => {
        fetchCouponsData(1, 10);
    };
    const handleSubscriptionStatusChange = (event, couponId) => {
        console.log('Switch toggled, new value:', event.target.checked);
        const newStatus = event.target.checked ? true : false;
        setCouponStatus(event.target.checked);
        updateSubscriptionStatus({ couponId, status: newStatus });
    };

    useEffect(() => {
        console.log("columndata", pageState?.data);
        let cols = [
            {
                field: "id",
                headerName: "CouponId",
                editable: false,
                hide: true,
                width: 140,
            },
            {
                field: "couponCodeText",
                headerName: "Coupon code",
                editable: false,
                width: 250,
            },
            {
                field: "createdBy",
                headerName: "createdBy",
                editable: false,
                width: 250,
                hide: true,
            },
            {
                field: "createdDate",
                headerName: "createdDate",
                editable: false,
                width: 250,
                hide: true,
            },
            {
                field: "discountPercentage",
                headerName: "Discount(%)",
                editable: false,
                width: 160,
            },
            {
                field: "resourceId",
                headerName: "ResourceId",
                editable: false,
                width: 150,
                hide: true
            },
            {
                field: "providerName",
                headerName: "Provider Name",
                editable: false,
                width: 200,
            },
            {
                field: "courseName",
                headerName: "Course Name",
                editable: false,
                width: 200,
            },
            {
                field: "validFrom",
                headerName: "Valid From",
                editable: false,
                width: 150,
                renderCell: (cellValues) => {
                    console.log("valid from ", cellValues);
                    if (cellValues.value != null) {
                        console.log(typeof (cellValues.row.ValidFrom), 'dates', cellValues.row.value)
                        const date = new Date(cellValues.value);
                        //const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
                        const localDate = new Date(date.getTime());
                        const dateTime = new Date(localDate);
                        // Get individual date and time components
                        const day = dateTime.getDate();
                        const month = dateTime.toLocaleString('default', { month: 'short' });
                        const year = dateTime.getFullYear();
                        // let hours = dateTime.getHours();
                        // const minutes = String(dateTime.getMinutes()).padStart(2, '0');
                        // const seconds = String(dateTime.getSeconds()).padStart(2, '0');
                        // const ampm = hours >= 12 ? 'PM' : 'AM';
                        // hours = hours % 12;
                        // hours = String(hours ? hours : 12).padStart(2, '0');

                        return `${day}-${month}-${year} `;
                    } else {
                        return "none"
                    }

                },
            },
            {
                field: "validTo",
                headerName: "Valid To",
                editable: false,
                width: 150,
                renderCell: (cellValues) => {
                    if (cellValues.formattedValue != null) {
                        console.log(typeof (cellValues.row.ValidTo), 'dates', cellValues.value)
                        const date = new Date(cellValues.value);
                        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
                        const dateTime = new Date(localDate);
                        // Get individual date and time components
                        const day = dateTime.getDate();
                        const month = dateTime.toLocaleString('default', { month: 'short' });
                        const year = dateTime.getFullYear();
                        // let hours = dateTime.getHours();
                        // const minutes = String(dateTime.getMinutes()).padStart(2, '0');
                        // const seconds = String(dateTime.getSeconds()).padStart(2, '0');
                        // const ampm = hours >= 12 ? 'PM' : 'AM';
                        // hours = hours % 12;
                        // hours = String(hours ? hours : 12).padStart(2, '0');

                        return `${day}-${month}-${year}`;
                    } else {
                        return "none"
                    }

                },
            },
            {
                field: "action",
                headerName: "Actions",
                editable: false,
                width: 150,
                filterable: false,
                sortable: false,

                renderCell: (cellValues) => (
                    <>
                        {
                            //isPrivilege("ViewTask") == true ?
                            // <Tooltip classes={{ tooltip: classes.tooltip }} title="Details">
                            //     <Button
                            //         size="sm"
                            //         justIcon
                            //         round
                            //         onClick={(e) => {
                            //             e.preventDefault();
                            //             editCouponFormModal(cellValues.row);
                            //         }}
                            //         style={{ size: "1px", backgroundColor: "#DD4B39" }}
                            //     >
                            //         <EditIcon />
                            //     </Button>
                            // </Tooltip>
                            <Tooltip title="Active status">
                                <Switch
                                    //value={subscriptionStatus}
                                    onChange={(event) => {
                                        handleSubscriptionStatusChange(event, cellValues.row.id);
                                    }}
                                    checked={cellValues.row.isActive ?? false}
                                />
                            </Tooltip>
                        }
                    </>
                )
            }
        ];
        setColumns(cols);
    }, []);


    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[-T:]/g, '');


    const onCellEditCommit = (p) => {
        let row = questionData.questions.find((x) => x.id == p.id);
        if (row[p.field] == p.value) {
            return;
        }
    };
    const getRowClassName = (row) => {
        if (row.isLocked == true) {
            return "gray";
        }

        return null;
    };

    return (
        <Card>
            <CardHeader color="rose" icon>
                <CardIcon color="rose">
                    <ListAlt />
                </CardIcon>
            </CardHeader>
            <CardBody>
                <GridContainer style={{ marginBottom: 21 }} justifyContent="flex-end" alignItems="flex-end" spacing={2} >

                    { // Button for Downloading Logs
                        <Button
                            color="primary"
                            onClick={addCouponFormModal}
                            style={{ marginTop: 20, marginRight: 10, textTransform: "none" }}
                            disabled={isLoading}
                        >

                            {isLoading ? '+ Add' : <span> + Add</span>}
                        </Button>
                    }

                </GridContainer>

                <div style={{ height: 660, width: "100%" }}>
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            {pageState.isLoading == false && pageState.data ?
                                <CustomDataGrid
                                    rows={pageState.data}
                                    rowCount={pageState.total}
                                    loading={pageState.isLoading}
                                    rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                    page={pageState.page - 1}
                                    pageSize={pageState.pageSize}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                                    onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                                    columns={columns}
                                    //onEditCellPropsChange={handleEdit}
                                    onCellEditCommit={onCellEditCommit}
                                    getRowClassName={getRowClassName}
                                />
                                :
                                <CustomDataGrid
                                    rows={[]}
                                    loading={pageState.isLoading}
                                    columns={columns}
                                    // checkboxSelection
                                    getRowClassName={getRowClassName}
                                />
                                // <h4 style={{ textAlign: 'center' }}>Task(s) not available</h4>
                            }
                        </div>
                    </div>
                </div>

            </CardBody>
            {addCouponForm}
            {/* {taskDetailsMap} */}
        </Card>
    );
}
