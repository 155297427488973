import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function useUserMapToCourse(courseId) {
    let queryClient = useQueryClient();
    let subscribed = false;
    return useMutation(async (payload) => {
        await axios.post(
            `${configUrl.AssessmentServer}/api/CourseUserMapping/bulkinsertCourseUserMappings/${subscribed}`,
            payload
        );
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(`availableUserByCourseIds${courseId}`)
            queryClient.invalidateQueries(`userByCourseIds${courseId}`)
        }
    })
}